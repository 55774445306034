

































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { TodoAny } from "@auditcloud/shared/lib/utils/type-guards";
import { isArray } from "lodash";
import { simplifyCustomMetadataAndAutoCompletionData } from "@auditcloud/shared/lib/utils/audit/scope";

@Component({})
export default class SnippetScope extends Vue {
  @Prop({
    type: [Object, String, Number, Array],
    default: () => [],
  })
  scope!: TodoAny;

  @Prop({
    type: String,
    default: "header",
  })
  format!: string;

  get currentScope() {
    console.log("currentScope", this.scope);

    const nomalized = simplifyCustomMetadataAndAutoCompletionData(
      this.scope,
      this.$i18n.locale,
      this.format === "header"
    );
    if (isArray(nomalized)) {
      return nomalized;
    } else {
      return [nomalized];
    }
  }
}
