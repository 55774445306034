




























































































































































































import { Component, Prop, Mixins } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import {
  TodoMap,
  TodoAny,
  typeIsArrayOf,
} from "@auditcloud/shared/lib/utils/type-guards";
import { simplifyCustomMetadataAndAutoCompletionData } from "@auditcloud/shared/lib/utils/audit/scope";

// Snippets / Controls
import ADateChip from "@auditcloud/components/snippets/ADateChip.vue";
import SnippetDate from "@/components/snippets/SnippetDate.vue";
import SnippetScope from "@/components/snippets/SnippetScope.vue";
import AAuditNameDisplay from "@/components/snippets/AAuditNameDisplay.vue";
import ADocumentStateSnippet from "@/components/snippets/ADocumentStateSnippet.vue";
import ItemsJsFacets from "@/components/controls/ItemsJsFacets.vue";
import MoreMenu from "@/components/snippets/MoreMenu.vue";
import PaginationControl from "@auditcloud/components/controls/PaginationControl.vue";
import AYearSwitch from "@/components/controls/AYearSwitch.vue";

// Layouts
import DrawerRight from "@/components/layouts/BaseLayouts/DrawerRight.vue";

// Mixins
import ListConfigurationMixin from "@/components/mixins/ListConfigurationMixin.vue";

import {
  api as auditsApi,
  Actions as AuditsActions,
  Getters as AuditsGetters,
} from "@/store/modules/audits";
import { api as usersApi } from "@/store/modules/users";
import { api as confApi } from "@/store/modules/configuration";

import { ROUTE_NAMES, dialogRoute, DIALOG_NAMES } from "@/routenames";
import { omit, Dictionary, toPairs, get } from "lodash";
import { AuditProgramConfig, UserRef } from "@auditcloud/shared/lib/schemas";
import { DataTableHeader } from "vuetify";
import {
  compareByAuditStatus,
  compareBySelfAssessmentStatus,
} from "@/utils/sort";
import { extractSelfAssessmentStatus } from "@auditcloud/shared/lib/utils/audit/auditStatus";
import {
  resolveSelfAssessmentProgress,
  resolveSelfAssessmentStatusText,
} from "@/utils/status_resolver";
import { typeIsNotEmpty } from "@auditcloud/shared/lib/utils/filter/typeIsNotEmpty";
import { typeIsUserRef } from "@auditcloud/shared/lib/schemas/type-guards";
import { UserRefToItemsJsNormalizer } from "@/types/User";

@Component({
  components: {
    SnippetDate,
    SnippetScope,
    ItemsJsFacets,
    DrawerRight,
    MoreMenu,
    PaginationControl,
    AAuditNameDisplay,
    ADocumentStateSnippet,
    AYearSwitch,
    ADateChip,
  },
  mixins: [ListConfigurationMixin],
})
export default class AAuditsTable extends Mixins(ListConfigurationMixin) {
  loadingStates: { [auditId: string]: undefined | "deleting" | false } = {};
  filterCount: number = 0;

  get more_menu() {
    return [
      // TODO: In Konfiguration
      {
        title: this.$i18n.t("views.audit_matrix.import").toString(),
        event: "itemimport",
      },
    ];
  }

  search: String = "";
  drawerRightVisible: boolean = false;
  dialogImport: boolean = false;
  currentAudits: any = []; // Filterd Audit from ItemsJS

  isUpdate: boolean = false;
  date: any = null;

  @Prop({
    default: null,
    type: String,
  })
  year!: string | null;

  @Prop({
    default: null,
    type: String,
  })
  auditProgramId!: string | null;

  @Getter(auditsApi.getters.getFlatAuditMatrix, {
    namespace: auditsApi.namespace,
  })
  getFlatAuditMatrix!: AuditsGetters["getFlatAuditMatrix"];

  get adminview() {
    if (this.$route.query.admin) {
      return true;
    } else {
      return false;
    }
  }

  // TODO: Name des getters eindeutiger
  @Getter(confApi.getters.audit, { namespace: confApi.namespace })
  audit!: TodoAny;

  @Getter(confApi.getters.getMappedAuditPrograms, {
    namespace: confApi.namespace,
  })
  mappedAuditPrograms!: Dictionary<AuditProgramConfig>;

  @Getter(confApi.getters.auditingYears, { namespace: confApi.namespace })
  auditingYears!: TodoAny;

  get tableHeader() {
    const HEADERS: DataTableHeader[] = [
      {
        align: "start",
        text: "views.audit_matrix.header_audit_program",
        value: "auditProgram",
        sortable: true,
      },
      {
        align: "start",
        text: "views.audit_matrix.header_audit_name",
        value: "auditname",
        sortable: true,
      },
      {
        text: "views.audit_matrix.header_standard",
        value: "standard",
      },
      {
        text: "views.audit_matrix.header_type",
        value: "type",
      },
      {
        text: "views.audit_matrix.header_audited_by",
        value: "audited_by",
      },
      {
        text: "views.audit_matrix.header_scope",
        value: "scope",
      },
      {
        text: "views.audit_matrix.header_auditor",
        value: "auditor",
      },
      {
        text: "views.audit_matrix.header_audit_created_at",
        value: "createdAt",
      },
      {
        text: "views.audit_matrix.header_auditing_date",
        value: "auditing_date",
      },
      {
        text: "views.audit_matrix.header_status",
        value: "status",
        sort: compareByAuditStatus,
      },
      {
        text: "views.audit_matrix.header_self_assessment_status",
        value: "self_assessment_status",
        sort: compareBySelfAssessmentStatus,
      },
      {
        sortable: false,
        text: "views.audit_matrix.header_actions",
        value: "edit",
      },
    ];

    return HEADERS.filter(header => {
      return this.columnConfig[header.value] ?? false;
    }).map(header => {
      return {
        ...header,
        text: this.$t(header.text).toString(),
      };
    });
  }

  @Getter(usersApi.getters.getUserRefToItemJsNormalizer, {
    namespace: usersApi.namespace,
  })
  normalizeDisplayName!: UserRefToItemsJsNormalizer;

  get audits() {
    console.log("currentAudits", this.getFlatAuditMatrix);

    const EMPTY_TEXT = "(leer)";
    // enrich with non flat but filterable data
    const config = this.configItemsJS;
    return this.getFlatAuditMatrix
      .map(v => {
        const { auditMeta } = v;

        const newEntries: Dictionary<
          | ReturnType<typeof simplifyCustomMetadataAndAutoCompletionData>
          | UserRef
        > = {};
        if (config.aggregations) {
          toPairs(config.aggregations).forEach(([key]) => {
            const valueAtKey = get(auditMeta, key);

            if (typeof valueAtKey !== "undefined") {
              if (typeIsUserRef(valueAtKey)) {
                newEntries[key] =
                  this.normalizeDisplayName(valueAtKey) ?? EMPTY_TEXT;
              } else if (typeIsArrayOf(valueAtKey, typeIsUserRef)) {
                newEntries[key] = valueAtKey.map(
                  u => this.normalizeDisplayName(u) ?? EMPTY_TEXT
                );
              } else {
                newEntries[key] = simplifyCustomMetadataAndAutoCompletionData(
                  valueAtKey,
                  this.$i18n.locale
                );
              }
            }
          });
        }

        const selfAssessmentStatus = extractSelfAssessmentStatus(auditMeta);
        const selfAssessmentStatusDescription = this.$ft(
          resolveSelfAssessmentStatusText(selfAssessmentStatus)
        );
        const saAnsweredCount =
          v.auditMeta.selfAssessmentAnsweredAuditItemIds.length;
        const saEnabledCount =
          v.auditMeta.selfAssessmentEnabledAuditItemIds.length;
        const selfAssessmentProgress = resolveSelfAssessmentProgress(
          selfAssessmentStatus,
          saAnsweredCount,
          saEnabledCount
        );
        const listScope = v[this.scopeProperty(v)];

        return {
          ...newEntries,
          ...v,
          listScope,
          scope: simplifyCustomMetadataAndAutoCompletionData(
            listScope,
            this.$i18n.locale
          ),
          status_code: v.status,
          status: this.$ft(v.status),
          self_assessment_status: selfAssessmentStatusDescription,
          self_assessment_progress: selfAssessmentProgress,
        };
      })
      .filter(typeIsNotEmpty);
  }
  get years() {
    return [
      ...this.auditingYears.map(year => {
        return {
          id: year,
          name: String(year),
        };
      }),
      { id: null, name: this.$t("views.audit_matrix.all_years") },
    ];
  }
  get activeYear() {
    const year = parseInt(this.year ?? "", 10);

    if (isNaN(year) || this.years.findIndex(v => v.id === year) === -1) {
      return null;
    } else {
      return year;
    }
  }

  get config() {
    return this.audit;
  }
  get columnConfig(): { [columnId: string]: boolean } {
    return {
      ...this.config.matrix,
      auditProgram:
        this.config.matrix.auditProgram && !this.isAuditProgramSpecific,
    };
  }

  // Overwrites from ListConfigurationMixin
  get listConfigConfigKey() {
    return "auditmatrix";
  }
  get listConfigTotalItemCount() {
    return this.currentAudits.length;
  }

  get auditProgramName(): string {
    if (this.auditProgramId) {
      return this.$ft(
        this.mappedAuditPrograms[this.auditProgramId]?.name ?? ""
      );
    } else {
      return "";
    }
  }

  get isAuditProgramSpecific() {
    return this.auditProgramId !== null;
  }

  get configItemsJS() {
    if (this.isAuditProgramSpecific) {
      return omit(this.listConfigItemsJs, ["aggregations.auditProgram"]);
    } else {
      return this.listConfigItemsJs;
    }
  }

  scopeProperty(matrixEntry: { type: string }): string {
    return this.$hasFeature("featureUseProcessAsScope") &&
      matrixEntry.type === "Prozessaudit"
      ? "process"
      : "scope";
  }

  @Action(auditsApi.actions.deleteAudit, { namespace: auditsApi.namespace })
  deleteAudit!: AuditsActions["deleteAudit"];

  onItemsjsResultListChanged(d: TodoAny, filter: TodoMap, query: string) {
    this.filterCount =
      (query === "" ? 0 : 1) +
      Object.values(filter).reduce((prev, item) => prev + item.length, 0);
    console.log("onItemsjsResultListChanged called", query, filter, d);
    this.currentAudits = d;
  }
  openEditDialog(auditId: string) {
    this.$router.push({
      name: dialogRoute(
        this.$route.name ?? "error",
        DIALOG_NAMES.AUDITMATRIX_EDIT
      ),
      params: {
        ...this.$route.params,
        auditId,
      },
      query: this.$route.query,
    });
  }

  openAudit(item: any) {
    const auditId = item.id;
    if (item.permissions.read) {
      this.$router.push({
        name: ROUTE_NAMES.AUDIT,
        params: {
          auditId,
        },
      });
    } else {
      this.openEditDialog(auditId);
    }
  }

  navigateToYear(year: null | number) {
    const params = {
      year: `${year ?? "all"}`,
    };
    this.$router.push({
      name: this.$route.name ?? "error",
      params,
    });
  }

  deleteItem(auditId: string) {
    if (confirm(this.$t("views.audit_matrix.delete_confirm").toString())) {
      this.$set(this.loadingStates, auditId, "deleting");
      this.deleteAudit({ auditId }).finally(() => {
        this.loadingStates[auditId] = false;
      });
    }
  }
}
