





















import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";

import { api as confApi } from "@/store/modules/configuration";

import { TodoAny } from "@auditcloud/shared/lib/utils/type-guards";

@Component({})
export default class AYearSwitch extends Vue {
  @Prop({
    default: null,
    type: String,
  })
  year!: string;

  @Getter(confApi.getters.auditingYears, { namespace: confApi.namespace })
  auditingYears!: TodoAny;

  get years() {
    let years: any[] = this.auditingYears.map(year => {
      return {
        id: year,
        name: String(year),
      };
    });

    years.push({ id: null, name: this.$t("views.audit_matrix.all_years") });

    return years;
  }
  get activeYear() {
    const year = parseInt(this.year, 10);

    if (isNaN(year) || this.years.findIndex(v => v.id === year) === -1) {
      return null;
    } else {
      return year;
    }
  }
  get currentYear() {
    return this.years.find(v => v.id === this.activeYear);
  }
}
