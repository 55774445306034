
import Vue, { CreateElement, VNode, RenderContext } from "vue";
import { formatDates } from "@auditcloud/shared/lib/utils/formatting/dates";
import { flatten } from "lodash";

function listStyle(h: CreateElement, formatedDates: string[]) {
  if (formatedDates.length <= 1) {
    return formatedDates;
  } else {
    const list = flatten(
      formatedDates.map(dateString => [dateString, h("br")])
    );
    list.pop();
    console.log("listStyle", list);
    return list;
  }
}

function chipStyle(h: CreateElement, formatedDates: string[]): VNode[] {
  return formatedDates.map((dateString, idx) => {
    return h(
      "v-chip",
      {
        props: {
          small: true,
          color: "grey lighten",
          textColor: "white",
        },
        key: idx,
      },
      [dateString]
    );
  });
}

export default {
  name: "SnippetDate",
  functional: true,
  props: {
    items: {
      type: [Array, String, Date],
      default: () => [],
    },
    format: {
      type: String,
      default: "list",
    },
  },
  render(h: CreateElement, context: RenderContext) {
    const isList = context.props.format === "list";
    const formatedDates = formatDates(context.props.items, null);
    return h("span", (isList ? listStyle : chipStyle)(h, formatedDates));
  },
};
