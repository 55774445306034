




import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { State, Action, Getter, namespace, Mutation } from "vuex-class";

enum ICONNAMES {
  DONE_ALL = "done_all",
  DONE = "done",
  CACHED = "cached",
}

@Component({
  components: {},
})
export default class ADocumentStateSnippet extends Vue {
  @Prop({
    type: Boolean,
    required: true,
  })
  hasPendingWrites!: boolean;

  @Prop({
    type: Boolean,
    required: true,
  })
  fromCache!: boolean;

  @Prop({
    type: Boolean,
    default: true,
  })
  small!: boolean;

  get iconName(): string {
    if (!this.hasPendingWrites && !this.fromCache) {
      return ICONNAMES.DONE_ALL;
    } else if (this.hasPendingWrites) {
      return ICONNAMES.DONE;
    } else {
      return ICONNAMES.CACHED;
    }
  }

  get classes(): string[] {
    if (this.iconName === ICONNAMES.DONE_ALL) {
      return ["green--text"];
    } else if (this.iconName === ICONNAMES.DONE) {
      return ["orange--text"];
    } else {
      return [];
    }
  }

  get title() {
    return this.$t(
      "components.snippets.document_state_snippet." + this.iconName
    );
  }
}
